import { environment } from 'src/environments/environment';
import { Category, CategoryItem, CustomizedCategoryItem, Filter, UserColumnSettings } from './categories';
import { DataSourceType } from '../services/data-service.factory';
import { DisplayNameSplit, SettingsType, TenantSettings, TocChartItem } from '../settings/settings.models';
import { DirectoryUserModification } from '../integrity/integrity.models';

export interface GraphUser {
    id: string;
    accountEnabled: string;
    companyName?: string;
    managerId?: string;
    displayName: string;
    jobTitle: string;
    department: string;
    city: string;
    country: string;
    mail: string;
    mobilePhone: string;
    state: string;
    userPrincipalName: string;
    faxNumber: string;
    givenName: string;
    surname: string;
    officeLocation: string;
    onPremisesExtensionAttributes?: OnPremisesExtensionAttributes;
    birthday?: string;
    employeeHireDate?: string;
    employeeId?: string;
    employeeType?: string;
    businessPhones?: string[];
    lastName?: string;
    streetAddress?: string;
    userType?: string;
    pictureUrl?: string;
    manager?: Partial<GraphUser>;
}

export interface PeoplePickerGraphUser extends GraphUser {
    TypeaheadTitle: string;
}

//create type def of { name: keyof DirectoryUser; displayName: string }[]
export type UserFields = { name: keyof DirectoryUser; displayName: string }[];

//Fields for directories based on Entra ID.
export const DirectoryUserFields: UserFields = [
    { name: 'displayName', displayName: 'Display Name' },
    { name: 'jobTitle', displayName: 'Job Title' },
    { name: 'department', displayName: 'Department' },
    { name: 'mail', displayName: 'Email' },
    { name: 'mobilePhone', displayName: 'Mobile Phone' },
    { name: 'city', displayName: 'City' },
    { name: 'country', displayName: 'Country' },
    { name: 'officeLocation', displayName: 'Office Location' },
    { name: 'id', displayName: 'ID' },
    { name: 'accountEnabled', displayName: 'Account Enabled' },
    { name: 'businessPhones', displayName: 'Business Phone' },
    { name: 'companyName', displayName: 'Company Name' },
    { name: 'employeeHireDate', displayName: 'Employee Hire Date' },
    { name: 'employeeId', displayName: 'Employee ID' },
    { name: 'employeeType', displayName: 'Employee Type' },
    { name: 'extensionAttribute1', displayName: 'Extension Attribute 1' },
    { name: 'extensionAttribute2', displayName: 'Extension Attribute 2' },
    { name: 'extensionAttribute3', displayName: 'Extension Attribute 3' },
    { name: 'extensionAttribute4', displayName: 'Extension Attribute 4' },
    { name: 'extensionAttribute5', displayName: 'Extension Attribute 5' },
    { name: 'extensionAttribute6', displayName: 'Extension Attribute 6' },
    { name: 'extensionAttribute7', displayName: 'Extension Attribute 7' },
    { name: 'extensionAttribute8', displayName: 'Extension Attribute 8' },
    { name: 'extensionAttribute9', displayName: 'Extension Attribute 9' },
    { name: 'extensionAttribute10', displayName: 'Extension Attribute 10' },
    { name: 'extensionAttribute11', displayName: 'Extension Attribute 11' },
    { name: 'extensionAttribute12', displayName: 'Extension Attribute 12' },
    { name: 'extensionAttribute13', displayName: 'Extension Attribute 13' },
    { name: 'extensionAttribute14', displayName: 'Extension Attribute 14' },
    { name: 'extensionAttribute15', displayName: 'Extension Attribute 15' },
    { name: 'firstName', displayName: 'First Name' },
    { name: 'givenName', displayName: 'Given Name' },
    { name: 'lastName', displayName: 'Last Name' },
    { name: 'faxNumber', displayName: 'Fax Number' },
    { name: 'state', displayName: 'State' },
    { name: 'streetAddress', displayName: 'Street Address' },
    { name: 'surname', displayName: 'Surname' },
    { name: 'userPrincipalName', displayName: 'User Principal Name' },
    { name: 'pictureUrl', displayName: 'Picture URL' },
    { name: 'userType', displayName: 'User Type' },
    { name: 'managerDisplayName', displayName: 'Manager' },
    { name: 'birthday', displayName: 'Birthday' }
];

//Fields for directories based on TeamOrgChart charts.
export const TOCUserFields: UserFields = [
    { name: 'displayName', displayName: 'Display Name' },
    { name: 'jobTitle', displayName: 'Job Title' },
    { name: 'department', displayName: 'Department' },
    { name: 'mail', displayName: 'Email' },
    { name: 'mobilePhone', displayName: 'Mobile Phone' },
    { name: 'city', displayName: 'City' },
    { name: 'country', displayName: 'Country' },
    { name: 'officeLocation', displayName: 'Office Location' },
    { name: 'id', displayName: 'ID' }
];

export function directoryFieldNamesDelta(): string {
    //same as directoryFieldNames, but without extensionAttribute1-15
    //return 'displayName,manager';
    return DirectoryUserFields.filter((field) => !field.name.startsWith('extensionAttribute'))
        .map((field) => field.name)
        .join(',');
}

export function directoryFieldNames(): string {
    return DirectoryUserFields.map((field) => field.name)
        .filter((x) => x !== 'birthday')
        .join(',');
}

export interface DirectoryUser {
    id: string;
    accountEnabled?: string;
    businessPhones?: string;
    city: string;
    companyName?: string;
    country?: string;
    department?: string;
    displayName: string;
    employeeHireDate?: string;
    employeeId?: string;
    employeeType?: string;
    extensionAttribute1?: string;
    extensionAttribute2?: string;
    extensionAttribute3?: string;
    extensionAttribute4?: string;
    extensionAttribute5?: string;
    extensionAttribute6?: string;
    extensionAttribute7?: string;
    extensionAttribute8?: string;
    extensionAttribute9?: string;
    extensionAttribute10?: string;
    extensionAttribute11?: string;
    extensionAttribute12?: string;
    extensionAttribute13?: string;
    extensionAttribute14?: string;
    extensionAttribute15?: string;
    firstName?: string;
    givenName?: string;
    jobTitle: string;
    lastName?: string;
    mail: string;
    mobilePhone: string;
    officeLocation: string;
    faxNumber: string;
    state?: string;
    streetAddress?: string;
    surname: string;
    userPrincipalName?: string;
    pictureUrl?: string;
    userType?: string;
    managerId?: string;
    managerDisplayName?: string;
    birthday?: string;
}

export function graphUserToDirectoryUser(
    graphUser: Partial<GraphUser>,
    displayNameSplit: DisplayNameSplit = DisplayNameSplit.FirstLast
): DirectoryUser {
    const user: DirectoryUser = {
        id: emptyStringIfNull(graphUser.id),
        accountEnabled: emptyStringIfNull(graphUser.accountEnabled),
        businessPhones: emptyStringIfNull(graphUser.businessPhones?.[0]),
        city: emptyStringIfNull(graphUser.city),
        companyName: emptyStringIfNull(graphUser.companyName),
        country: emptyStringIfNull(graphUser.country),
        department: emptyStringIfNull(graphUser.department),
        displayName: emptyStringIfNull(graphUser.displayName),
        employeeHireDate: emptyStringIfNull(graphUser.employeeHireDate),
        employeeId: emptyStringIfNull(graphUser.employeeId),
        employeeType: emptyStringIfNull(graphUser.employeeType),
        firstName: emptyStringIfNull(graphUser.givenName),
        givenName: emptyStringIfNull(graphUser.givenName),
        jobTitle: emptyStringIfNull(graphUser.jobTitle),
        lastName: emptyStringIfNull(graphUser.lastName) || emptyStringIfNull(graphUser.surname),
        mail: emptyStringIfNull(graphUser.mail) || emptyStringIfNull(graphUser.userPrincipalName),
        mobilePhone: emptyStringIfNull(graphUser.mobilePhone),
        officeLocation: emptyStringIfNull(graphUser.officeLocation),
        faxNumber: emptyStringIfNull(graphUser.faxNumber),
        state: emptyStringIfNull(graphUser.state),
        streetAddress: emptyStringIfNull(graphUser.streetAddress),
        surname: emptyStringIfNull(graphUser.surname),
        userPrincipalName: emptyStringIfNull(graphUser.userPrincipalName),
        pictureUrl: emptyStringIfNull(graphUser.pictureUrl),
        userType: emptyStringIfNull(graphUser.userType),
        managerId: emptyStringIfNull(graphUser.managerId),
        birthday: emptyStringIfNull(graphUser.birthday)
    };

    if (graphUser.onPremisesExtensionAttributes) {
        const extensionAttributes = graphUser.onPremisesExtensionAttributes;
        user.extensionAttribute1 = emptyStringIfNull(extensionAttributes.extensionAttribute1);
        user.extensionAttribute2 = emptyStringIfNull(extensionAttributes.extensionAttribute2);
        user.extensionAttribute3 = emptyStringIfNull(extensionAttributes.extensionAttribute3);
        user.extensionAttribute4 = emptyStringIfNull(extensionAttributes.extensionAttribute4);
        user.extensionAttribute5 = emptyStringIfNull(extensionAttributes.extensionAttribute5);
        user.extensionAttribute6 = emptyStringIfNull(extensionAttributes.extensionAttribute6);
        user.extensionAttribute7 = emptyStringIfNull(extensionAttributes.extensionAttribute7);
        user.extensionAttribute8 = emptyStringIfNull(extensionAttributes.extensionAttribute8);
        user.extensionAttribute9 = emptyStringIfNull(extensionAttributes.extensionAttribute9);
        user.extensionAttribute10 = emptyStringIfNull(extensionAttributes.extensionAttribute10);
        user.extensionAttribute11 = emptyStringIfNull(extensionAttributes.extensionAttribute11);
        user.extensionAttribute12 = emptyStringIfNull(extensionAttributes.extensionAttribute12);
        user.extensionAttribute13 = emptyStringIfNull(extensionAttributes.extensionAttribute13);
        user.extensionAttribute14 = emptyStringIfNull(extensionAttributes.extensionAttribute14);
        user.extensionAttribute15 = emptyStringIfNull(extensionAttributes.extensionAttribute15);
    }

    if (graphUser.manager) {
        user.managerId = emptyStringIfNull(graphUser.manager.id);
        user.managerDisplayName = emptyStringIfNull(graphUser.manager.displayName);
    }

    splitDisplayName(user, displayNameSplit);

    return user;
}

export interface OnPremisesExtensionAttributes {
    extensionAttribute1: string;
    extensionAttribute2: string;
    extensionAttribute3: string;
    extensionAttribute4: string;
    extensionAttribute5: string;
    extensionAttribute6: string;
    extensionAttribute7: string;
    extensionAttribute8: string;
    extensionAttribute9: string;
    extensionAttribute10: string;
    extensionAttribute11: string;
    extensionAttribute12: string;
    extensionAttribute13: string;
    extensionAttribute14: string;
    extensionAttribute15: string;
}

export function chartItemToDirectoryUser(
    chartItem: TocChartItem,
    displayNameSplit: DisplayNameSplit = DisplayNameSplit.FirstLast,
    allItems: TocChartItem[]
): DirectoryUser {
    console.log('chartItemToDirectoryUser', chartItem);
    const user: DirectoryUser = {
        id: emptyStringIfNull(chartItem.UniqueId),
        accountEnabled: '',
        businessPhones: '',
        city: emptyStringIfNull(chartItem.City),
        companyName: emptyStringIfNull(chartItem.CompanyName),
        country: emptyStringIfNull(chartItem.Country),
        department: emptyStringIfNull(chartItem.Group),
        displayName: emptyStringIfNull(chartItem.DisplayName),
        jobTitle: emptyStringIfNull(chartItem.JobTitle),
        mail: emptyStringIfNull(chartItem.Email),
        mobilePhone: emptyStringIfNull(chartItem.Phone),
        officeLocation: emptyStringIfNull(chartItem.OfficeLocation),
        employeeHireDate: '',
        employeeId: '',
        employeeType: '',
        firstName: '',
        givenName: '',
        lastName: emptyStringIfNull(null),
        faxNumber: emptyStringIfNull(null),
        state: '',
        streetAddress: '',
        surname: emptyStringIfNull(null),
        userPrincipalName: '',
        pictureUrl: emptyStringIfNull(
            chartItem.PictureUrl != null
                ? environment.tocUrl + chartItem.PictureUrl
                : environment.tocUrl + chartItem.TOC_Fields.PictureUrl
        ),
        userType: '',
        managerId: emptyStringIfNull(chartItem.ManagerId),
        managerDisplayName: allItems ? allItems.find((item) => item.UniqueId === chartItem.ManagerId)?.DisplayName : ''
    };

    splitDisplayName(user, displayNameSplit);

    return user;
}

function splitDisplayName(user: DirectoryUser, displayNameSplit: DisplayNameSplit): DirectoryUser {
    if (user.displayName != null && (!user.firstName || !user.lastName)) {
        const split = user.displayName.split(' ');

        if (displayNameSplit === DisplayNameSplit.FirstLast) {
            if (split.length > 0 && !user.firstName) {
                user.firstName = split[0];
            }
            if (split.length > 1 && (!user.lastName || (user.lastName === user.firstName && split[0] !== split[1]))) {
                user.lastName = split[split.length - 1];
            }
        } else if (displayNameSplit === DisplayNameSplit.LastFirst) {
            if (split.length > 0 && (!user.lastName || (user.lastName === user.firstName && split[0] !== split[1]))) {
                user.lastName = split[0];
            }
            if (split.length > 1 && !user.firstName) {
                user.firstName = split[split.length - 1];
            }
        }
    }
    return user;
}

function emptyStringIfNull(value: string | null): string {
    return value ?? '';
}

export class MsGraphUsersResponse {
    value: GraphUser[];
    '@odata.nextLink': string;
    '@odata.deltaLink'?: string;
}

export class MsGraphDeltaResponse {
    '@odata.nextLink': string;
    '@odata.deltaLink'?: string;
    value: (GraphUser | MsGraphUserChangeInfo)[];
}

export class MsGraphBatchUserResponse {
    responses: MsGraphBatchUserDetails[];
}

export class MsGraphBatchUserDetails {
    id: string;
    status: number;
    headers: unknown;
    body: Partial<GraphUser>;
}

export class MsGraphUserChangeInfo {}

export interface SavedUserPhoto {
    id: string;
    photo: string;
}

export interface CategoryImage {
    id: string;
    imageBytes: string;
}

export interface SavedCategoryItemPicture {
    categoryId: number;
    categoryItemId: number;
    picture: string;
}

export interface DataFromDb {
    dataSourceType: DataSourceType;
    users: DirectoryUser[];
    filteredUsers: DirectoryUser[];
    categories: Category[];
    savedCategories: Category[];
    savedCategoryItems: CustomizedCategoryItem[];
    userColumnSettings: UserColumnSettings;
    userModifications: DirectoryUserModification[];
    filters: Filter[];
    favorites: string[];
    isRefreshNeeded?: boolean;
}

export interface SearchResults {
    users: DirectoryUser[];
    categorySearchResults: CategorySearchResults[];
    usersPage: DirectoryUser[];
}

export interface FavoritesModel {
    favorites: string[];
    id: number;
}

export interface TenantSettingsModel {
    tenantSettings: TenantSettings;
    id: number;
}

export interface CategorySearchResults {
    categoryId: number;
    name: string;
    slug: string;
    icon: string;
    items: CategoryItem[];
}

export enum UsersViewMode {
    Cards,
    Table,
    Faces,
    Calendar,
    Chart
}

export enum CategoryItemsViewMode {
    Cards,
    Table
}

export type CategoryChosenViewMode = {
    category: Category;
    viewMode: CategoryItemsViewMode;
};

export type CategoryChosenSortField = {
    category: Category;
    sortField: string;
};

export type CategoryChosenSortOrder = {
    category: Category;
    sortOrder: SortOrder;
};

export enum DirModals {
    PersonDetails = 'personDetails',
    EditCategoryItem = 'editCategoryItem',
    SubscriptionInformation = 'subscriptionInformation',
    Help = 'help',
    IntegrityApply = 'integrityApply',
    ChartSettings = 'chartSettings'
}

export enum SortOrder {
    Asc,
    Desc
}

export type SortField = keyof DirectoryUser;

export type GroupByField = keyof DirectoryUser | null;

export interface DisplayedUsersGroup {
    title: string;
    fieldName: keyof DirectoryUser;
    fieldValue: string;
    categoryItem?: CategoryItem;
    users: DirectoryUser[];
    originalUsersCount: number;
}

export interface SettingsTimestamps {
    timestamp: string;
    unixTimestamp: number;
}

type ValueOf<T> = T[keyof T];

//type Slices = Record<ValueOf

export interface Metadata {
    id: number;
    // timestamp: string;
    // unixTimestamp: number;
    // filtersTimestamp: string;
    // filtersUnixTimestamp: number;
    // favoritesTimestamp: string;
    // favoritesUnixTimestamp: number;
    // tenantSettingsTimestamp: string;
    // tenantSettingsUnixTimestamp: number;
    deltaLink?: string;
    slices: Partial<Record<ValueOf<typeof SettingsType>, SettingsTimestamps>>;
}

export const initializeMetadata = (): Metadata => {
    const initialTimestamps: SettingsTimestamps = { timestamp: '', unixTimestamp: 0 };

    const slices = Object.values(SettingsType).reduce(
        (acc, key) => {
            acc[key] = { ...initialTimestamps };
            return acc;
        },
        {} as Record<SettingsType, SettingsTimestamps>
    );

    return {
        id: 1,
        slices
    };
};

export const RefreshTimestampKey = 'TeamDirectoryRefreshTimestamp';
