<div class="modal-header">
    <i class="bi bi-diagram-3 fs-1"></i>
    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
    @if (activeView === viewType.People) {
    <h2>Chart settings for People view</h2>
    } @else if (activeView === viewType.Favorites) {
    <h2>Chart settings for Favorites view</h2>
    } @else {
    <h2>Chart settings for {{ categoryItem.name }}</h2>
    }
    <!-- {{ startingPerson | json }} -
    {{ startingPersonId | json }} -->
    <div class="col-md-6">
        <label for="startingPerson" class="form-label fs-5 fw-semibold mb-2">Pick a starting person</label>
        <input
            type="text"
            class="form-control"
            id="startingPerson"
            placeholder="Start typing a name"
            [ngModel]="startingPersonDisplayedName"
            (ngModelChange)="onChange($event)"
            typeaheadOptionField="displayName"
            (typeaheadOnSelect)="selectUser($event)"
            [typeahead]="users"
            [typeaheadItemTemplate]="userTemplate" />
        @if (startingPersonId === '') {
        <p>Without starting person set, the chart will start with the head of the longest chain of reports for the current view.</p>
        }
        <label for="depth" class="form-label fs-5 fw-semibold mb-2">Starting chart depth</label>
        <input type="number" class="form-control" id="depth" [(ngModel)]="depth" min="1" max="20" />
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" (click)="onSave()" [disabled]="isSavingFromDialog">Save</button>
    <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
</div>
<ng-template #userTemplate let-model="item" let-index="index">
    {{ model.displayName }}
</ng-template>
