import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { DirModals } from '../../data/data.models';
import * as fromRoot from '../../reducers';
import * as dataActions from '../../data/data.actions';
import { PersonDetailsModalComponent } from '../modals/person-details-modal.component';
import { CategoryItemEditorModalComponent } from '../categories/category-item-editor-modal.component';
import { SubscriptionInformationModalComponent } from './subscription-information-modal.component';
import { HelpModalComponent } from 'src/app/components/modals/help-modal.component';
import { IntegrityApplyModalComponent } from './integrity-apply-modal.component';
import { ChartSettingsModalComponent } from './chart-settings-modal.component';

@Component({
    selector: 'dir-modal-root',
    template: '<span></span>',
    standalone: true
})
export class ModalRootComponent implements OnInit, OnDestroy {
    bsModalRef: BsModalRef;
    bsModalRefs: BsModalRef[] = [];
    openedModalNames$: Observable<string[]>;
    private unsubscribe$ = new Subject<void>();
    constructor(private store: Store, private bsModalService: BsModalService) {
        this.openedModalNames$ = this.store.pipe(select(fromRoot.selectOpenedModalNames));
    }

    ngOnInit(): void {
        this.openedModalNames$
            .pipe(
                takeUntil(this.unsubscribe$),
                withLatestFrom(this.store.pipe(select(fromRoot.selectOpenedModalOptions)))
            )
            .subscribe(([names, options]) => {
                if (names.length === 0) {
                    this.bsModalRefs.forEach((x) => {
                        x.hide();
                    });
                    this.bsModalRefs = [];
                    this.bsModalRef = null;
                }
                if (this.bsModalRefs.length > names.length) {
                    if (this.bsModalRefs.length > 1) {
                        //multiple modals open
                        this.bsModalRefs[this.bsModalRefs.length - 1].hide();
                        this.bsModalRefs = this.bsModalRefs.slice(0, this.bsModalRefs.length - 1);
                        this.bsModalRef = this.bsModalRefs[this.bsModalRefs.length - 1];
                    } else if (this.bsModalRef) {
                        this.bsModalRef.hide();
                        this.bsModalRefs = [];
                    }
                    return;
                }

                const name = names[names.length - 1];
                switch (name) {
                    case DirModals.PersonDetails: {
                        //add modal-fullscreen-md-down class to options.class if it's not there
                        if (options == null) {
                            options = {};
                        }
                        if (options['class'] == null) {
                            options['class'] = '';
                        }
                        if (!options['class']?.includes('modal-fullscreen-md-down')) {
                            options['class'] += ' modal-fullscreen-md-down';
                        }
                        this.openModal(PersonDetailsModalComponent, options);
                        break;
                    }
                    case DirModals.EditCategoryItem: {
                        this.openModal(CategoryItemEditorModalComponent, options);
                        break;
                    }
                    case DirModals.SubscriptionInformation: {
                        this.openModal(SubscriptionInformationModalComponent, options);
                        break;
                    }
                    case DirModals.Help: {
                        this.openModal(HelpModalComponent, options);
                        break;
                    }
                    case DirModals.IntegrityApply: {
                        this.openModal(IntegrityApplyModalComponent, options);
                        break;
                    }
                    case DirModals.ChartSettings: {
                        this.openModal(ChartSettingsModalComponent, options);
                        break;
                    }
                    default: {
                        if (this.bsModalRefs.length > 1) {
                            //multiple modals open
                            this.bsModalRefs[this.bsModalRefs.length - 1].hide();
                            this.bsModalRefs = this.bsModalRefs.slice(0, this.bsModalRefs.length - 1);
                            this.bsModalRef = this.bsModalRefs[this.bsModalRefs.length - 1];
                        } else if (this.bsModalRef) {
                            this.bsModalRef.hide();
                            this.bsModalRefs = [];
                        }
                    }
                }
            });
        this.bsModalService.onHidden
            .pipe(
                takeUntil(this.unsubscribe$),
                withLatestFrom(this.store.pipe(select(fromRoot.selectOpenedModalNames)))
            )
            .subscribe(([reason, names]) => {
                if (names.length > 0) {
                    const name = names[names.length - 1];
                    if (
                        name === 'DirModals.CreateRule || name === DirModals.EssentialColumns' &&
                        this.bsModalService.getModalsCount() === 1
                    ) {
                        return;
                    }
                }
                if (names.length === this.bsModalRefs.length && reason !== 'backdrop-click' && reason !== 'esc') {
                    return; //we're in consistent state, do not call hide
                }
                this.store.dispatch(dataActions.dialogHide());
            });
    }

    openModal<T>(component: ComponentType<T>, options: ModalOptions<unknown>) {
        if (options && options['class']) {
            options['class'] += ' modal-dialog-centered modal-lg';
        } else {
            options = { ...options, class: 'modal-dialog-centered modal-lg' };
        }
        //options = { ...options, animated: false };
        this.bsModalRef = this.bsModalService.show(component, options);
        this.bsModalRefs.push(this.bsModalRef);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type ComponentType<T> = new (...args: any[]) => T;
