import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as DataActions from '../data/data.actions';
import * as AppActions from '../app.actions';
import * as AuthActions from '../auth/auth.actions';
import * as fromRoot from '../reducers';
import { AppDB } from '../services/db.service';
import { SettingsService } from '../services/settings.service';
import * as SettingsActions from './settings.actions';
import * as categoryUtils from '../utils/category.utils';
import * as imageActions from '../images/image.actions';
import * as appActions from '../app.actions';
import { SecurityService } from '../services/security.service';
import { logger } from '../utils/logger';
import { SettingsDataMap, SettingsServerResponse, SettingsType } from './settings.models';

@Injectable()
export class SettingsEffects {
    constructor(
        private actions$: Actions,
        private settingsService: SettingsService,
        private securityService: SecurityService,
        private store: Store,
        private db: AppDB
    ) {}

    loadSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.loadSettings),
            switchMap(() =>
                this.db.getMetadata().pipe(
                    switchMap((metadata) =>
                        this.settingsService.loadSettings(metadata).pipe(
                            switchMap((response) => [
                                SettingsActions.loadSettingsSuccess({ response, metadata }),
                                imageActions.loadAllImagesFromServer()
                            ]),
                            catchError((error) => this.handleError(error, 'loadSettings'))
                        )
                    )
                )
            )
        )
    );

    loadSettingsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.loadSettingsSuccess),
            switchMap((action) => {
                return this.db.getMetadata().pipe(
                    switchMap((metadata) => {
                        logger.debug('timestamps');
                        logger.debug(metadata?.slices?.settings?.timestamp, action.response.settings?.timestamps);
                        if (this.areSettingsUpToDate(action.response)) {
                            logger.info('settings are up to date');
                            return this.db.getTenantSettings().pipe(
                                map((tenantSettings) =>
                                    SettingsActions.loadSettingsSuccessNoChange({
                                        response: action.response,
                                        tenantSettings
                                    })
                                )
                            );
                        } else {
                            return of(SettingsActions.loadSettingsSuccessWithChange({ response: action.response }));
                        }
                    })
                );
            })
        )
    );

    loadSettingsSuccessWithChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.loadSettingsSuccessWithChange),
            switchMap((action) => {
                logger.info('settings are not up to date');
                const response = action.response;
                return this.db.saveSettingsSlicesToDatabase(response).pipe(
                    map((x) => DataActions.rebuildCategoriesFromDb()),
                    catchError((error) => this.handleError(error, 'loadSettingsSuccessWithChange'))
                );
            })
        )
    );

    saveFavoritesSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.saveFavoritesSuccess),
                switchMap((action) => {
                    logger.trace('saveFavoritesSuccess$');
                    logger.trace(action);
                    return this.db.saveSettingsSlicesToDatabase(action.response).pipe(
                        catchError((error) => {
                            logger.error('error');
                            logger.error(error);
                            return of(SettingsActions.loadSettingsFailure({ error: error.message }));
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    saveFiltersSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.saveFiltersSuccess),
                switchMap((action) => {
                    logger.trace('saveFiltersSuccess$');
                    logger.trace(action);
                    return this.db.saveSettingsSlicesToDatabase(action.response).pipe(
                        catchError((error) => {
                            logger.error('error');
                            logger.error(error);
                            return of(SettingsActions.loadSettingsFailure({ error: error.message }));
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    saveFavorites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveFavorites),
            switchMap((action) =>
                this.saveSettingsSlice(SettingsType.Favorites, action.favorites, SettingsActions.saveFavoritesSuccess)
            )
        )
    );

    saveIntegrity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveIntegrity),
            switchMap((action) =>
                this.saveSettingsSlice(SettingsType.Integrity, action.integrity, SettingsActions.saveIntegritySuccess)
            )
        )
    );

    saveUserModifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveUserModifications),
            switchMap((action) =>
                this.saveSettingsSlice(
                    SettingsType.Modifications,
                    action.userModifications,
                    SettingsActions.saveUserModificationsSuccess
                )
            )
        )
    );

    saveUserModificationsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveUserModificationsSuccess),
            switchMap((action) => of(appActions.toastSuccess({ message: 'User modifications saved' })))
        )
    );

    saveFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveFilters),
            switchMap((action) =>
                this.saveSettingsSlice(SettingsType.Filters, action.filters, SettingsActions.saveFiltersSuccess)
            )
        )
    );

    saveTenantSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveTenantSettings, SettingsActions.saveTenantSettingsFromDialog),
            switchMap((action) =>
                this.saveSettingsSlice(
                    SettingsType.TenantSettings,
                    action.tenant,
                    SettingsActions.saveTenantSettingsSuccess
                )
            )
        )
    );

    toggleFavorite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DataActions.toggleFavorite),
            mergeMap((action) =>
                this.db.getMetadata().pipe(
                    withLatestFrom(this.store.select(fromRoot.selectFavorites)),
                    mergeMap(([metadata, favorites]) =>
                        this.db
                            .saveSettingsSlicesToDatabase({
                                favorites: {
                                    value: favorites,
                                    timestamps: {
                                        timestamp: metadata?.slices?.favorites?.timestamp ?? '',
                                        unixTimestamp: metadata?.slices?.favorites?.unixTimestamp ?? 0
                                    }
                                }
                            })
                            .pipe(
                                switchMap(() =>
                                    this.settingsService
                                        .saveSettingsSlice(SettingsType.Favorites, favorites, metadata)
                                        .pipe(
                                            map((response) => {
                                                return SettingsActions.saveFavoritesSuccess({
                                                    response
                                                });
                                            }),
                                            catchError((error) => {
                                                logger.error(error);
                                                return of(
                                                    SettingsActions.saveFavoritesFailure({
                                                        message: error.message,
                                                        status: error.status,
                                                        error: error.error
                                                    })
                                                );
                                            })
                                        )
                                )
                            )
                    )
                )
            )
        )
    );

    saveSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveSettings),
            switchMap((action) =>
                //save with current timestamp
                this.db.getMetadata().pipe(
                    switchMap((metadata) =>
                        this.db
                            .saveSettingsSlicesToDatabase({
                                settings: {
                                    value: action.settings,
                                    timestamps: {
                                        timestamp: metadata?.slices?.settings?.timestamp ?? '',
                                        unixTimestamp: metadata?.slices?.settings.unixTimestamp ?? 0
                                    }
                                }
                            })
                            .pipe(
                                switchMap(() =>
                                    this.db.getMetadata().pipe(
                                        switchMap((metadata) =>
                                            this.settingsService
                                                .saveSettingsSlice(SettingsType.Settings, action.settings, metadata)
                                                .pipe(
                                                    map((response) => {
                                                        return SettingsActions.saveSettingsSuccess({ response });
                                                    }),
                                                    catchError((error) => {
                                                        logger.error(error.message);
                                                        return of(
                                                            SettingsActions.saveSettingsFailure({
                                                                message: error.message,
                                                                status: error.status,
                                                                error: error.error
                                                            })
                                                        );
                                                    })
                                                )
                                        )
                                    )
                                )
                            )
                    )
                )
            )
        )
    );

    saveSettingsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                SettingsActions.saveSettingsSuccess,
                SettingsActions.saveCategoryItemsInSettingsSuccess,
                SettingsActions.saveFiltersSuccess,
                SettingsActions.saveUserModificationsSuccess
            ),
            switchMap((action) =>
                this.db.updateMetadata(action.response).pipe(map(() => DataActions.rebuildCategoriesFromDb()))
            )
        );
    });

    saveSettingsSuccessNoRebuild$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    SettingsActions.saveFavoritesSuccess,
                    SettingsActions.saveTenantSettingsSuccess,
                    SettingsActions.saveIntegritySuccess
                ),
                switchMap((action) => this.db.updateMetadata(action.response).pipe(map(() => of(EMPTY))))
            ),
        { dispatch: false }
    );

    saveSettingsSuccessToast$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingsActions.saveSettingsSuccess, SettingsActions.saveCategoryItemsInSettingsSuccess),
            map((action) => AppActions.toastSuccess({ message: 'Settings saved' }))
        );
    });

    filtersSavedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingsActions.saveFiltersSuccess),
            map((action) => AppActions.toastSuccess({ message: 'Filters saved' }))
        );
    });

    tenantSettingsSavedSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveTenantSettingsSuccess),
            map(() => AppActions.toastSuccess({ message: 'Settings saved' }))
        )
    );

    tenantSettingsSavedSuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveTenantSettingsSuccess),
            map(() => DataActions.reloadUsers())
        )
    );

    saveCategoryItemsInSettingsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingsActions.saveCategoryItemsInSettingsSuccess),
            map((action) =>
                DataActions.saveCategoryItemSuccess({
                    savedCategoryItems: categoryUtils.flattenCategoryItemsByCategory(
                        action.response.settings.value.categoryItemsByCategory
                    )
                })
            )
        );
    });

    saveAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingsActions.saveAdmin),
            switchMap((action) =>
                this.securityService.saveAdmin(action.objectId, action.userName).pipe(
                    map((admins) => {
                        return SettingsActions.saveAdminSuccess({ admins });
                    }),
                    catchError((error) => {
                        logger.error(error);
                        return of(AppActions.toastFailure({ message: 'Failed to add admin ' + error }));
                    })
                )
            )
        );
    });

    loadAdmins$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingsActions.loadAdmins),
            switchMap((action) =>
                this.securityService.loadAdmins().pipe(
                    map((admins) => {
                        return SettingsActions.loadAdminsSuccess({ admins });
                    }),
                    catchError((error) => {
                        logger.error(error);
                        return of(AppActions.toastFailure({ message: 'Failed to load administrators' }));
                    })
                )
            )
        );
    });

    deleteAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingsActions.deleteAdmin),
            switchMap((action) =>
                this.securityService.deleteAdmin(action.objectId).pipe(
                    map((admins) => {
                        return SettingsActions.deleteAdminSuccess({ admins });
                    }),
                    catchError((error) => {
                        logger.error(error);
                        return of(AppActions.toastFailure({ message: 'Failed delete admins' + error }));
                    })
                )
            )
        );
    });

    saveCategoryItemsInSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.saveCategoryItemsInSettings),
            concatLatestFrom(() => [
                this.store.select(fromRoot.selectSavedCategories),
                this.store.select(fromRoot.selectUserColumnSettings)
            ]),
            switchMap(([action, savedCategories, userColumnSettings]) => {
                logger.debug('saveCategoryItemsInSettings$', action.savedCategoryItems, savedCategories);
                const categoryItemsByCategory = categoryUtils.arrangeCategoryItemsByCategory(
                    action.savedCategoryItems,
                    savedCategories
                );
                return this.db.replaceCategoryItems(action.savedCategoryItems).pipe(
                    switchMap(() =>
                        this.saveSettingsSlice(
                            SettingsType.Settings,
                            {
                                categories: savedCategories,
                                categoryItemsByCategory,
                                userColumnSettings
                            },
                            SettingsActions.saveCategoryItemsInSettingsSuccess
                        )
                    )
                );
            })
        )
    );

    saveFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SettingsActions.saveSettingsFailure,
                SettingsActions.saveFavoritesFailure,
                SettingsActions.saveFiltersFailure,
                SettingsActions.saveTenantSettingsFailure
            ),
            map((action) => AppActions.toastFailure({ message: 'Failed to save settings, out of sync with server' }))
        )
    );

    forceReloadSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.forceReloadSettings),
            switchMap(() => this.db.clearMetadata().pipe(map(() => SettingsActions.loadSettings())))
        )
    );

    resetServerSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.resetServerSettings),
            switchMap(() =>
                this.settingsService.resetSettings().pipe(
                    map((settings) => SettingsActions.resetServerSettingsSuccess()),
                    catchError((error) => this.handleError(error, 'resetServerSettings'))
                )
            )
        )
    );

    connectToChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.connectToChart),
            switchMap((action) =>
                this.settingsService.createDataSource(action.dataSource).pipe(
                    map((dataSource) => SettingsActions.connectToChartSuccess()),
                    catchError((error) => {
                        logger.error(error);
                        return of(SettingsActions.connectToChartFailure({ error: error.message }));
                    })
                )
            )
        )
    );

    removeDataSource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.removeDataSource),
            switchMap((action) =>
                this.settingsService.removeDataSource(action.id).pipe(
                    map(() => SettingsActions.removeDataSourceSuccess()),
                    catchError((error) => {
                        logger.error(error);
                        return of(SettingsActions.removeDataSourceFailure({ error: error.message }));
                    })
                )
            )
        )
    );

    updateDataSource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.updateDataSource),
            switchMap((action) =>
                this.settingsService.updateDataSource(action.dataSource).pipe(
                    map(() => SettingsActions.updateDataSourceSuccess()),
                    catchError((error) => {
                        logger.error(error);
                        return of(SettingsActions.updateDataSourceFailure({ error: error.message }));
                    })
                )
            )
        )
    );

    toastSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.updateDataSourceSuccess),
            map((action) => AppActions.toastSuccess({ message: 'Settings saved' }))
        )
    );

    //Reload tenant account when data sources are changed
    reloadTenantAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SettingsActions.removeDataSourceSuccess,
                SettingsActions.connectToChartSuccess,
                SettingsActions.updateDataSourceSuccess
            ),
            map(() => AuthActions.loadTenant())
        )
    );

    private handleError = (error: { errorCode: unknown; errorMessage: unknown }, actionType: string) => {
        logger.error(`Error in ${actionType}:`, error);
        return of(
            SettingsActions.loadSettingsFailure({
                error: { errorCode: error?.errorCode, errorMessage: error?.errorMessage }
            })
        );
    };

    // saveSettingsSlice<K extends keyof SettingsDataMap>(
    //     sliceKey: K,
    //     data: SettingsDataMap[K],
    //     metadata: Metadata
    // ):

    private saveSettingsSlice<K extends keyof SettingsDataMap>(
        sliceKey: K,
        data: SettingsDataMap[K],
        successAction: SaveSettingsSucccessAction
    ) {
        logger.debug('saveSettingsSlice', sliceKey, data);
        return this.db.getMetadata().pipe(
            switchMap((metadata) =>
                this.settingsService.saveSettingsSlice(sliceKey, data, metadata).pipe(
                    map((response) => successAction({ response })),
                    catchError((error) => this.handleError(error, 'saveSettingsSlice'))
                )
            )
        );
    }

    private areSettingsUpToDate(response: SettingsServerResponse): boolean {
        return (
            response.settings == null &&
            response.filters == null &&
            response.favorites == null &&
            response.tenantsettings == null &&
            response.integrity == null &&
            response.modifications == null
        );
    }
}

type SaveSettingsSucccessAction = ActionCreator<
    string,
    (props: { response: SettingsServerResponse }) => {
        response: SettingsServerResponse;
    } & Action<string>
>;
