import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { CategoryImage } from '../data/data.models';
import * as SettingsActions from './settings.actions';
import * as ImageActions from '../images/image.actions';
import * as DataActions from '../data/data.actions';
import { UserPermission } from '../auth/auth.models';
import { ChartMeta, DisplayNameSplit, TocConnectionState } from './settings.models';
import { CategoryChartSettings, defaultChartSettings } from '../data/categories';

export const settingsFeatureKey = 'settings';

export interface State {
    //timestamp?: string;
    //unixTimestamp: number;
    hasLoadedSettingsFromServer: boolean;
    hasFailedToLoadSettingsFromServer: boolean;
    hasProcessedSettings: boolean;
    //TO MOVE INTO OWN STATE
    editedImage: EditedImageInfo | null;
    refreshedImageId: string;
    admins: UserPermission[];
    chartMetas: ChartMeta[];
    tocConnectionState: TocConnectionState;
    tocTenantExists: boolean | false;
    hasTimestampMismatch: boolean;
    isCalendarHidden: boolean;
    isScheduleHidden: boolean;
    displayNameSplit: DisplayNameSplit;
    chartSettings: CategoryChartSettings;
    favoritesChartSettings: CategoryChartSettings;
}

export interface EditedImageInfo {
    image: CategoryImage;
    pattern: string;
    isImage: boolean;
}

export const initialState: State = {
    //timestamp: null,
    //unixTimestamp: 0,
    editedImage: null,
    hasLoadedSettingsFromServer: false,
    hasFailedToLoadSettingsFromServer: false,
    hasProcessedSettings: false,
    refreshedImageId: '',
    admins: [],
    chartMetas: [],
    tocConnectionState: TocConnectionState.Disconnected,
    tocTenantExists: null,
    hasTimestampMismatch: false,
    isCalendarHidden: false,
    isScheduleHidden: false,
    displayNameSplit: DisplayNameSplit.FirstLast,
    chartSettings: { ...defaultChartSettings },
    favoritesChartSettings: { ...defaultChartSettings }
};

export const reducer = createReducer(
    initialState,
    on(SettingsActions.updateSettings, (state, action) => ({ ...state, ...action.settings })),
    on(ImageActions.editedImageInformation, (state, action) => ({ ...state, editedImage: action.editedImage })),

    on(ImageActions.loadImageFromServerSuccess, (state, action) => ({
        ...state,
        refreshedImageId: action.imageId
    })),
    on(ImageActions.saveImageSuccess, (state, action) => ({
        ...state,
        refreshedImageId: action.id
    })),
    on(SettingsActions.loadSettingsSuccess, (state, action) => ({
        ...state,
        hasLoadedSettingsFromServer: true,
        hasFailedToLoadSettingsFromServer: false,
        //timestamp: action.response.settings?.timestamp ?? action.metadata?.timestamp,
        //unixTimestamp: action.response.settings?.unixTimestamp ?? action.metadata?.unixTimestamp,
        hasTimestampMismatch: false,
        isCalendarHidden: action.response.tenantsettings?.value?.isCalendarHidden ?? false,
        isScheduleHidden: action.response.tenantsettings?.value?.isScheduleHidden ?? false,
        displayNameSplit: action.response.tenantsettings?.value?.displayNameSplit ?? DisplayNameSplit.FirstLast,
        chartSettings: action.response.tenantsettings?.value?.chartSettings ?? defaultChartSettings,
        favoritesChartSettings: action.response.tenantsettings?.value?.favoritesChartSettings ?? defaultChartSettings
    })),
    on(SettingsActions.loadSettingsSuccessNoChange, (state, action) => ({
        ...state,
        hasProcessedSettings: true,
        hasTimestampMismatch: false,
        isCalendarHidden: action.tenantSettings?.isCalendarHidden ?? false,
        isScheduleHidden: action.tenantSettings?.isScheduleHidden ?? false,
        displayNameSplit: action.tenantSettings?.displayNameSplit ?? DisplayNameSplit.FirstLast,
        chartSettings: action.tenantSettings?.chartSettings ?? defaultChartSettings,
        favoritesChartSettings: action.tenantSettings?.favoritesChartSettings ?? defaultChartSettings
    })),
    on(
        DataActions.rebuildCategoriesFromDbSuccess,
        DataActions.rebuildCategoriesFromDbWithUsersSuccess,
        (state, action) => ({
            ...state,
            hasProcessedSettings: true
        })
    ),
    on(SettingsActions.loadSettingsFailure, (state, action) => ({
        ...state,
        hasProcessedSettings: true,
        hasFailedToLoadSettingsFromServer: true
    })),
    on(
        SettingsActions.saveSettingsSuccess,
        SettingsActions.saveCategoryItemsInSettingsSuccess,
        (state, { response }) => ({ ...state, ...response.settings.value, pattern: '', categoryImage: null })
    ),
    on(
        SettingsActions.loadAdminsSuccess,
        SettingsActions.deleteAdminSuccess,
        SettingsActions.saveAdminSuccess,
        (state, action) => ({ ...state, admins: action.admins })
    ),
    on(SettingsActions.fetchCharts, (state) => ({
        ...state,
        tocConnectionState: TocConnectionState.Connecting
    })),
    on(SettingsActions.fetchChartsFailure, (state, action) => {
        if (action.status === 404) {
            return {
                ...state,
                tocConnectionState: TocConnectionState.NotFound
            };
        }
        return {
            ...state,
            tocConnectionState: TocConnectionState.Error
        };
    }),
    on(SettingsActions.fetchChartsSuccess, (state, action) => ({
        ...state,
        chartMetas: action.charts.chartMetas,
        tocConnectionState:
            action.charts?.chartMetas?.length === 0 ? TocConnectionState.NoCharts : TocConnectionState.ChartsFetched
    })),
    on(SettingsActions.checkTenantExistsSuccess, (state, action) => {
        if (!action.exists) {
            return {
                ...state,
                tocTenantExists: action.exists,
                tocConnectionState: TocConnectionState.NotFound
            };
        } else {
            return {
                ...state,
                tocTenantExists: action.exists
            };
        }
    }),
    on(SettingsActions.checkTenantExistsFailure, (state, action) => ({
        ...state,
        tocConnectionState: TocConnectionState.Error
    })),
    on(
        SettingsActions.saveSettingsFailure,
        SettingsActions.saveFavoritesFailure,
        SettingsActions.saveFiltersFailure,
        SettingsActions.saveTenantSettingsFailure,
        (state, action) => {
            if (action.status === 409) {
                return {
                    ...state,
                    hasTimestampMismatch: true
                };
            }
            return state;
        }
    )
);

// export const selectHasLoadedSettingsFromServer = (state: State) => state.hasLoadedSettingsFromServer;
// export const selectHasFailedToLoadSettingsFromServer = (state: State) => state.hasFailedToLoadSettingsFromServer;
// export const selectHasProcessedSettings = (state: State) => state.hasProcessedSettings;
// //export const selectTimestamp = (state: State) => state.timestamp;
// //export const selectUnixTimestamp = (state: State) => state.unixTimestamp;
// export const selectAdmins = (state: State) => state.admins;

// export const selectEditedImageInfo = (state: State) => state.editedImage;
// export const selectRefreshedImageId = (state: State) => state.refreshedImageId;
// export const selectChartMetas = (state: State) => state.chartMetas;
// export const selectTocConnectionState = (state: State) => state.tocConnectionState;
// export const selectTocTenantExists = (state: State) => state.tocTenantExists;
// export const selectHasTimestampMismatch = (state: State) => state.hasTimestampMismatch;
// export const selectIsCalendarHidden = (state: State) => state.isCalendarHidden;
// export const selectDisplayNameSplit = (state: State) => state.displayNameSplit;
// export const selectTenantSettings = createSelector(
//     selectIsCalendarHidden,
//     selectDisplayNameSplit,
//     (isCalendarHidden, displayNameSplit) => ({
//         isCalendarHidden,
//         displayNameSplit
//     })
// );

export const settingsFeature = createFeature({
    name: settingsFeatureKey,
    reducer,
    extraSelectors: ({
        selectIsCalendarHidden,
        selectDisplayNameSplit,
        selectIsScheduleHidden,
        selectChartSettings,
        selectFavoritesChartSettings
    }) => {
        //console.warn('EXTRA SELECTORS FOR SETTINGS');
        return {
            selectTenantSettings: createSelector(
                selectIsCalendarHidden,
                selectDisplayNameSplit,
                selectIsScheduleHidden,
                selectChartSettings,
                selectFavoritesChartSettings,
                (isCalendarHidden, displayNameSplit, isScheduleHidden, chartSettings, favoritesChartSettings) => ({
                    isCalendarHidden,
                    displayNameSplit,
                    isScheduleHidden,
                    chartSettings,
                    favoritesChartSettings
                })
            )
        };
    }
});
