import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { DirectoryUser } from '../../data/data.models';
import { GraphImageService } from '../../services/graph-image.service';
import { Subject, takeUntil } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { ImageFallbackDirective } from '../directives/image-fallback.directive';

@Component({
    selector: 'dir-graph-picture',
    templateUrl: './graph-picture.component.html',
    styleUrls: ['./graph-picture.component.scss'],
    imports: [NgClass, NgIf, ImageFallbackDirective]
})
export class GraphPictureComponent implements OnInit, OnDestroy {
    private _directoryUser: DirectoryUser;

    @Input()
    get directoryUser(): DirectoryUser {
        return this._directoryUser;
    }

    set directoryUser(user: DirectoryUser) {
        this._directoryUser = user;
        this.loadDirectoryUserImage();
    }

    @Input() size = 35;
    @Input() classes = '';
    @Input() edgeStyle: 'circle' | 'square' | '' = '';
    @Input() id = '';

    @Input() isCurrentUser = false;
    @Input() showDefaultPicture = false;

    userInitials = '';
    isImageLoading = signal(false);
    showFallback = signal(false);
    imageSrc: WritableSignal<string | ArrayBuffer> = signal('');

    private unsubscribe$ = new Subject<void>();
    constructor(private imageService: GraphImageService) {}

    ngOnInit(): void {
        this.isImageLoading.set(false);
        this.showFallback.set(false);

        if (this.id) {
            this.getImageFromService(this.id);
        }

        this.loadDirectoryUserImage();
    }

    private loadDirectoryUserImage() {
        if (this.directoryUser) {
            if (this.directoryUser.pictureUrl) {
                this.imageSrc.set(this.directoryUser.pictureUrl);
            } else {
                this.getImageFromService(this.directoryUser.id);
            }
            //combine first letters of first and last names into userInitials
            this.userInitials =
                this.directoryUser.firstName != null && this.directoryUser.firstName.trim().length !== 0
                    ? this.directoryUser.firstName[0]
                    : '';
            this.userInitials +=
                this.directoryUser.lastName != null && this.directoryUser.lastName.trim().length !== 0
                    ? this.directoryUser.lastName[0]
                    : '';
        }
    }

    getImageFromService(objectId: string) {
        //console.log('idididid: ', this.id);
        //console.log('getImageFromService', objectId);
        this.isImageLoading.set(true);

        this.imageService
            .getImage(objectId, this.isCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (data) => {
                    if (data != null && data !== '') {
                        this.imageSrc.set(data);
                        //console.warn('imageSrc', objectId);
                        this.isImageLoading.set(false);
                        this.showFallback.set(false);
                        this.showDefaultPicture = false;
                    } else {
                        this.isImageLoading.set(false);
                        this.showFallback.set(true);
                    }
                    //this.cdr.detectChanges();
                },
                error: (error) => {
                    this.isImageLoading.set(false);
                    this.showFallback.set(true);
                }
            });
    }

    getSize() {
        if (this.size == 35) {
            return 'symbol-sm-35px symbol-35px';
        }
        return 'symbol-' + this.size + 'px';
    }

    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.imageSrc.set(reader.result);
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
