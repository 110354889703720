import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CustomizedCategoryItem } from 'src/app/data/categories';
import * as dataActions from '../../data/data.actions';
import { FormsModule } from '@angular/forms';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DirectoryUser } from 'src/app/data/data.models';
import * as fromRoot from '../../reducers';
import { Subject, take, takeUntil } from 'rxjs';
import { ViewType } from 'src/app/app.models';
import { settingsFeature } from 'src/app/settings/settings.reducer';
import * as settingsActions from 'src/app/settings/settings.actions';

@Component({
    selector: 'dir-chart-settings-modal',
    imports: [FormsModule, TypeaheadModule],
    templateUrl: './chart-settings-modal.component.html',
    styleUrl: './chart-settings-modal.component.scss'
})
export class ChartSettingsModalComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    categoryItem: CustomizedCategoryItem;
    activeView: ViewType;
    viewType = ViewType;
    startingPersonId: string;
    startingPerson: DirectoryUser;
    startingPersonDisplayedName: string;
    depth = 3;
    users: DirectoryUser[] = [];
    isSavingFromDialog = false;

    constructor(private store: Store) {
        this.store.pipe(select(fromRoot.selectUsersUnsorted), takeUntil(this.unsubscribe$)).subscribe((users) => {
            this.users = users;
            if (this.startingPersonId) {
                this.startingPerson = this.users.find((x) => x.id === this.startingPersonId);
                this.startingPersonDisplayedName = this.startingPerson?.displayName;
            }
        });
        this.store
            .pipe(select(fromRoot.selectIsSavingFromDialog), takeUntil(this.unsubscribe$))
            .subscribe((isSavingFromDialog) => {
                if (this.isSavingFromDialog && !isSavingFromDialog) {
                    this.closeModal();
                }
                this.isSavingFromDialog = isSavingFromDialog;
            });
    }

    ngOnInit(): void {
        this.store.pipe(select(fromRoot.selectChartSettings), takeUntil(this.unsubscribe$)).subscribe((settings) => {
            this.startingPersonId = settings?.startingPersonId;
            this.depth = settings?.depth ?? 3;
            if (this.users && this.users.length > 0) {
                this.startingPerson = this.users.find((x) => x.id === this.startingPersonId);
                this.startingPersonDisplayedName = this.startingPerson?.displayName;
            }
        });
    }

    onChange($event: string) {
        console.log($event);
        if ($event === '') {
            this.startingPerson = null;
            this.startingPersonId = '';
        }
    }

    selectUser(match: TypeaheadMatch<DirectoryUser>) {
        this.startingPersonId = match.item.id;
        this.startingPerson = match.item;
    }

    onSave() {
        if (this.activeView === ViewType.CategoryItem) {
            this.store.dispatch(
                dataActions.saveCategoryItem({
                    categoryItem: {
                        ...this.categoryItem,
                        chartSettings: {
                            startingPersonId: this.startingPersonId,
                            depth: this.depth
                        }
                    }
                })
            );
        } else if (this.activeView === ViewType.People || this.activeView === ViewType.Favorites) {
            this.store.pipe(take(1), select(settingsFeature.selectTenantSettings)).subscribe((tenantSettings) => {
                const tenant = { ...tenantSettings };
                if (this.activeView === ViewType.People) {
                    tenant.chartSettings = {
                        startingPersonId: this.startingPersonId,
                        depth: this.depth
                    };
                } else if (this.activeView === ViewType.Favorites) {
                    tenant.favoritesChartSettings = {
                        startingPersonId: this.startingPersonId,
                        depth: this.depth
                    };
                }
                this.store.dispatch(settingsActions.saveTenantSettingsFromDialog({ tenant }));
            });
        }
    }

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
